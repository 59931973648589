import {
  ErrorBoundary,
  Suspense,
  createSignal,
  Show,
  createMemo,
  Switch,
  Match,
  onMount,
  createEffect,
  startTransition,
  onCleanup,
} from "solid-js";
import {
  type RouteDefinition,
  type RouteSectionProps,
  A,
  useNavigate,
  useSearchParams,
  createAsync,
  AccessorWithLatest,
  useSubmission,
  revalidate,
  action,
} from "@solidjs/router";
import { createStore } from "solid-js/store";
import {
  useSessionContext,
  useSiteContext,
  CheckoutContext,
  useErrorContext,
} from "~/utils/contexts";
import { retrieveCart, submitCheckoutAction, listCarts } from "~/services/cart";
import { PERMISSION } from "~/services/roma-api/account/types";
import { getAccountStatus } from "~/services/account";
import { isNullUndefinedOrEmpty, numberWithCommas } from "~/utils/helpers";
import { PT } from "~/utils/products";
import { EventType } from "@solid-primitives/analytics";
import { BaseLoader } from "~/components/utility";
import {
  verifyInventoryLevels,
  groupCartLines,
  validateMinAmount,
} from "~/components/ordering/checkout/utils";
import { Motion, Presence } from "solid-motionone";
import Button from "~/components/Button";
import { Icon } from "solid-heroicons";
import {
  questionMarkCircle,
  checkCircle,
  minusCircle,
  chevronLeft,
  chevronRight,
  arrowLongRight,
  exclamationCircle,
  chevronDown,
} from "solid-heroicons/outline";
import {
  CartDetail,
  SubmittedCartDetail,
} from "~/services/roma-api/cart/types";
import {
  InventoryByPlant,
  PlantCodeKeys,
} from "~/services/roma-api/products/types";
import { displayDate } from "~/utils/helpers";
import {
  CartDropdown,
  BPSCheckout,
  PONumber,
} from "~/components/ordering/checkout";
import { ListCartResponse } from "~/services/roma-api/cart";
import {
  editCartItemAction,
  removeFromCartAction,
  clearCartAction,
  updateCurrentCartAction,
} from "~/services/cart";
import { useAction } from "@solidjs/router";
import { LineItem } from "~/services/roma-api/cart/types-lineItems";
import { usePrompt } from "~/components/utility";
import { OrderAddress } from "~/services/roma-api/admin/orders";
import { ConfettiExplosion } from "solid-confetti-explosion";
import { APIError } from "~/services/roma-api/errors";
import { checkError } from "~/services/roma-api/checkError";
import { GenericError } from "~/components/utility/errors";
import { AuthBarrier } from "~/components/utility/AuthBarrier";
import { getSession } from "~/services/session";

// TODO
// // ! deal with sending()
// ! remove types to checkout/utils..?
// ! extract the track functions - they are reused throughout checkout pages, looks ugly.

export const route = {
  preload: ({ params }) => {
    try {
      retrieveCart(params.cart_id);
    } catch (error) {
      console.log("[cart_id].tsx preload fn: caught error");
    }
  },
} satisfies RouteDefinition;

export default function CheckoutLanding(props: RouteSectionProps) {
  // TODO - permission check on PlaceOrder
  const { isPartner, permission, session, aCartActionIsPending, clearSession } =
    useSessionContext();
  const { track, trackFBQ, headerVisible } = useSiteContext();
  const { addError, notifications } = useErrorContext();
  const [cartError, setCartError] = createSignal<null | Error>(null);

  // Tried using memo to trigger the createAsync firing on the param change..didn't work
  // const pageCartID = createMemo((prev) => {
  //   console.log("------------ MEMO FIRING --------------");
  //   console.log("PREV VALUE: ", prev);
  //   console.log("CURRENT PROPS.PARAMS.CART_ID: ", props.params.cart_id);
  //   return props.params.cart_id;
  // });

  const cart = createAsync(
    async () => {
      const session = await getSession();
      const isAuthenticated = session?.token;
      // const cart_id = pageCartID();

      if (!isAuthenticated) {
        return EMPTYCART;
      }
      try {
        // Get the ID from either props or parse it from the URL as fallback
        // const cartId =
        //   props.params.cart_id || window?.location?.pathname.split("/")[2];

        const cart = (await retrieveCart(
          props.params.cart_id
        )) as FormattedCart;

        // if (!cart) {
        //   return EMPTYCART;
        // }

        // * FORMATTING SUBMITTED CART * //
        if (cart?.Status == "submitted") {
          // TODO - Get shape of submitted cart..
          const cartCopy = { ...cart };
          // check if there are any corner sample orders in the array or Orders - if so, splice it and push it to the end of the array;
          const cornerSamples = cartCopy.Orders.find(
            (order) => order.Type == "Corner Samples"
          );
          if (cornerSamples) {
            const index = cartCopy.Orders.indexOf(cornerSamples);
            cartCopy.Orders.splice(index, 1);
            cartCopy.Orders.push(cornerSamples);
          }
          return cartCopy;
        }

        // * INVENTORY LEVELS CHECK * //
        try {
          // try verifying stock levels for all mouldings in cart
          // in case of failure, we still return the cart but flag it as unverified,
          // and dispatch error notification. Could be improved depending on error code
          // from retrieveBulkInventory fetch
          return await verifyInventoryLevels(cart);
        } catch (error) {
          const err = checkError(error);
          addError(err, {
            severity: "warning",
            autoDisappear: true,
            title: "Stock Verification",
            message:
              "An error occurred while verifying stock levels. Mouldings with low stock may be placed on backorder if you proceed with checkout.",
            showDetails: true,
          });
          return { ...cart, StockVerificationComplete: false };
        }
      } catch (error) {
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log(
            "[[cart_id].tsx]: Caught error in createAsync catch:",
            err
          );
        }
        startTransition(() => setCartError(err));
        return EMPTYCART;
      }
    },
    { deferStream: false }
  );
  const carts = createAsync(
    async () => {
      return await listCarts();
    },
    { deferStream: false }
  );

  const status = createAsync(
    async () => {
      try {
        const data = await getAccountStatus();
        return data;
      } catch (error) {
        if (import.meta.env.DEV) {
          console.log(
            "[cart_id].tsx: Caught accountStatus error on createAsync: ",
            error
          );
        }
        const err = checkError(error);

        addError(err, {
          title: "Account Status Error",
          message:
            "Error retrieving your account status/permissions. If you encounter errors placing this order please contact support.",
          severity: "warning",
          showDetails: true,
        });
      }
    },
    { deferStream: false }
  );

  onCleanup(() => setCartError(null));

  const [showCartDropdown, setShowCartDropdown] = createSignal(false);
  // TODO
  const [sendCartError, setSendCartError] = createSignal(false);
  const [searchParams] = useSearchParams(); //? necessary?
  const [Prompt, openPrompt] = usePrompt();

  const navigate = useNavigate();

  // * CHECKOUT DATA STORE * /
  const [checkoutData, setCheckoutData] = createStore<CheckoutStore>({
    shipToChoice: undefined,
    shipCustom: false,
    pickup: false,
    PONumber: "",
    promoCode: "test",
    orderNotes: undefined,
    orderProcess: "default",
    customLocation: {
      Recipient: undefined,
      Number: undefined,
      Street: undefined,
      Street2: undefined,
      City: undefined,
      Region: undefined,
      Country: undefined,
      PostalCode: undefined,
      Phone: undefined,
      Email: undefined,
    },
    CustomerID: undefined,
  });

  //* UTILS */
  const mode = createMemo(() => {
    if (props.location.pathname.includes("review")) {
      return "REVIEW";
    } else if (props.location.pathname.includes("status")) {
      return "STATUS";
    } else {
      return "OVERVIEW";
    }
  });
  const lines = createMemo(() => groupCartLines(cart() as FormattedCart));
  const minAmountMet = createMemo(() => validateMinAmount(lines()));
  const cannotPickupGFPF = createMemo(() => {
    // If a cart contains GF / PF, AND the users default plant is not
    // CA or LA (BP01 / BP03), user cannot place order as PICKUP:
    if (!lines()?.photoFrame.isInCart && !lines()?.galleryFrame.isInCart)
      return false;
    return !["BP01", "BP03"].includes(cart()?.DefaultPlant as PlantCodeKeys);
  });
  // const isBPS = createMemo(
  //   () => !!status()?.Permissions.includes(PERMISSION.PLACEORDER)
  // );
  const isBPS = createMemo(() => permission.BPSPLACEORDER);

  const BPSContainsNonCorner = createMemo(() => {
    if (!isBPS()) return false;
    if (
      isBPS() &&
      checkoutData.CustomerID &&
      (lines().products.length > 0 ||
        lines().photoFrame.isInCart ||
        lines().galleryFrame.isInCart)
    ) {
      return true;
    } else {
      return false;
    }
  });
  const isReviewValid = createMemo(() => {
    if (mode() === "REVIEW") {
      if (!isNullUndefinedOrEmpty(checkoutData.shipToChoice)) {
        if (checkoutData.shipCustom === true) {
          const form = checkoutData.customLocation;
          if (
            isNullUndefinedOrEmpty(form.Recipient) ||
            isNullUndefinedOrEmpty(form.Email) ||
            isNullUndefinedOrEmpty(form.Phone) ||
            isNullUndefinedOrEmpty(form.Number) ||
            isNullUndefinedOrEmpty(form.Street) ||
            isNullUndefinedOrEmpty(form.City) ||
            isNullUndefinedOrEmpty(form.Country) ||
            isNullUndefinedOrEmpty(form.Region) ||
            isNullUndefinedOrEmpty(form.PostalCode)
          ) {
            return false;
          }
        }

        if (checkoutData.orderProcess === "delay") {
          if (isNullUndefinedOrEmpty(checkoutData.shipDate)) {
            return false;
          }
        }
        return true;
      }
      return false;
    }
    return true;
  });
  //* END UTILS */

  // * CART/LINE ACTIONS * //
  const editLineItem = useAction(editCartItemAction);
  const deleteLineItem = useAction(removeFromCartAction);
  const emptyCart = useAction(clearCartAction);
  const sendCheckout = useAction(submitCheckoutAction);
  const updateCurrentCart = useAction(updateCurrentCartAction);

  const editingLine = useSubmission(editCartItemAction);

  const getItemFromCart = (id: string) => {
    const currentCart = cart();
    if (!currentCart || !currentCart.Lines) return undefined;

    const index = currentCart.Lines.findIndex((item) => item.ID == id);
    return index !== -1 ? currentCart.Lines[index] : undefined;
  };

  const revalidateTheCart = action(async () => {
    return await revalidate(retrieveCart.keyFor(props.params.cart_id));
  });

  const reval = useAction(revalidateTheCart);

  // ? manual revalidation...
  // This is to solve an issue where pressing the 'view cart and checkout' button in the cart
  // panel was not triggering update to the currently displayed cart.
  // props.params.cart_id was changing, but the createAsync was not refiring or responding to the change.
  createEffect(() => {
    if (props.location.state === "revalidate") {
      if (cart()?.ID !== props.params.cart_id) {
        reval();
      }
    }
  });

  const updateQuantity = async (id: string, qty: number, type: PT) => {
    try {
      const item = getItemFromCart(id);
      if (item) {
        const modifiedItem = [PT.LENGTH, PT.CONTRACT].includes(type)
          ? { ...item, SubItems: [{ ...item.SubItems[0], Length: qty }] }
          : { ...item, Quantity: qty };
        await editLineItem(id, modifiedItem as LineItem);
      }
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log("[updateQuantity]: caught error: ", err);
      }
      addError(err, {
        severity: "critical",
        title: "Error Updating Line Item",
        message:
          "An error occurred while updating this line item. If the error persists, please contact support.",
        autoDisappear: true,
        showDetails: true,
      });

      console.log("revalidating");
      reval();

      throw err;
    }
  };

  const removeLine = async (id: string) => {
    const ok = await openPrompt({
      title: "Confirm Item Deletion",
      description:
        "Are you sure you want to delete this item? This action is irreversible.",
      requireInteraction: true,
      options: [
        { label: "Cancel - Return to Cart", value: false, priority: true },
        { label: "Delete Item", value: true, style: "red" },
      ],
    });
    if (ok) {
      try {
        await deleteLineItem(id);
      } catch (error) {
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log("[cart_id].tsx: removeLinefn caught error: ", err);
        }
        addError(err, {
          title: "Deletion Error",
          message:
            "An error occurred while deleting this line item. If the error perists, please contact support.",
          autoDisappear: true,
          showDetails: true,
          severity: "critical",
        });
        console.log("revalidating");
        reval();
      }
    }
  };

  const clearCart = async () => {
    const ok = await openPrompt({
      title: "Empty Current Cart",
      description:
        "Are you sure you want to remove all items from this cart? This action is irreversible.",
      requireInteraction: true,
      options: [
        { label: "Cancel - Return to Cart", value: false, priority: true },
        { label: "Empty Cart", value: true, style: "red" },
      ],
    });

    if (ok) {
      try {
        await emptyCart();
      } catch (error) {
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log("[cart_id].tsx: clearCartfn caught error: ", err);
        }
        addError(err, {
          severity: "critical",
          title: "Error Emptying Cart",
          message:
            "An error occurred while emptying your cart. If the error persists, please contact support.",
          autoDisappear: false,
          showDetails: true,
        });
        if (import.meta.env.DEV) {
          console.log("revalidating");
        }
        reval();
      }
    }
  };

  const handlePlaceOrder = async () => {
    // TODO - try/catch, set UI error on failure..

    const payload = {
      ShipTo: checkoutData.shipToChoice as string,
      ShipCustom: checkoutData.shipCustom,
      Pickup: checkoutData.pickup ?? false,
      PONumber: checkoutData.PONumber,
      InternalNotes: "",
      ExternalNotes: checkoutData.orderNotes ?? "",
      ShipToDetail: checkoutData.shipCustom
        ? (checkoutData.customLocation as Optional<OrderAddress, "Street2">)
        : undefined,
      ProcessDate:
        checkoutData.orderProcess === "delay"
          ? checkoutData.shipDate
          : undefined,
      CustomerNumber:
        isBPS() && checkoutData.CustomerID ? checkoutData.CustomerID : "",
    };

    try {
      const { CurrentCartID, Status, ID } = await sendCheckout({
        id: props.params.cart_id,
        details: payload,
      });

      if (Status === "submitted" && !!CurrentCartID && !!ID) {
        // set the new CurrentCartID ?
        await updateCurrentCart(CurrentCartID);
        navigate(`/checkout/${ID}/status?placed=true`);
      }
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log("[handlePlaceOrder]: caught error: ", err);
      }
      addError(err, {
        severity: "critical",
        title: "Error Placing Order",
        message:
          "An error occurred while submitting this order. If the error persists, please contact support.",
        suggestions: [
          "Refresh this page",
          "Sign out / Sign in and proceed with checkout again.",
        ],
        actions: [
          {
            label: "Refresh",
            action: () => {
              window.location.reload();
            },
          },
          {
            label: "Sign Out",
            action: async () => {
              await clearSession();
            },
            dismissAfter: true,
          },
          {
            label: "Contact Support",
            action: async () => {
              navigate("/support");
            },
          },
        ],
        autoDisappear: false,
        showDetails: true,
      });
      throw err;
    }
  };

  const disableCheckoutButtons = createMemo(() => {
    return (
      aCartActionIsPending() ||
      minAmountMet().valid == false ||
      cart()?.LineCount === 0 ||
      cart.latest?.ProductRequiresBackorder ||
      BPSContainsNonCorner() ||
      cart()?.Status === "submitted" ||
      cart()?.Status !== "active" ||
      !isReviewValid()
    );
  });

  // * END CART/LINE ACTIONS * //

  // TRACK
  onMount(() => {
    if (mode() === "OVERVIEW" && cart()?.Lines) {
      track(EventType.Event, {
        category: "view_cart",
        // @ts-expect-error
        currency: status()?.Currency,
        value: cart()?.Amount.toString(),
        items: cart()?.Lines.map((item: LineItem) => {
          const category = item.SubItems[0]?.Moulding
            ? cart()?.Products?.[item.SubItems[0]?.Moulding]?.Category
            : undefined;
          const collection = item.SubItems[0]?.Moulding
            ? cart()?.Products?.[item.SubItems[0]?.Moulding]?.Collection
            : undefined;
          return {
            item_id: item.SubItems[0].SKU,
            item_name: `${category}, ${collection}`,
            price: item.Amount,
            discount: item.Discount,
            quantity: item.CalculatedQuantity,
            item_category: item.Type,
            item_category2: category,
            item_category3: collection,
          };
        }),
      });
    }
  });

  // TESTING

  // onMount(()=>{
  //   if (cart() && cart()?.Status === "submitted") {
  //     if (!props.location.pathname.includes("status")) {
  //       navigate(`/checkout/${props.params.cart_id}/status`)
  //     }
  //   }
  // })

  const [showBar, setShowBar] = createSignal(false);
  const [isHeaderVisible, isTop] = headerVisible;
  let headerHeight: string = "95px";
  onMount(() => {
    const el = document.getElementById("header-navigation") as HTMLDivElement;
    if (el) {
      headerHeight = window.getComputedStyle(el).height;
    }
  });

  return (
    <>
      <CheckoutContext.Provider
        value={{
          cart,
          lines,
          minAmountMet,
          checkoutData,
          setCheckoutData,
          updateQuantity,
          removeLine,
          clearCart,
          BPSContainsNonCorner,
          isBPS,
          cannotPickupGFPF,
          isReviewValid,
          handlePlaceOrder,
          disableCheckoutButtons,
        }}
      >
        <AuthBarrier
          fallbackRoot={(props) => (
            <div class="min-h-[50vh] flex items-center">{props.children}</div>
          )}
          permissions={"PLACEORDER"}
        >
          <ErrorBoundary
            fallback={(err, reset) => {
              // TODO - Error-Boundary
              return (
                <div class="m-6">
                  <GenericError
                    class="max-w-3xl rounded-md mx-auto p-6"
                    error={err}
                    reset={reset}
                  />{" "}
                </div>
              );
            }}
          >
            {/* HERE !!!! */}
            {/* REPLACEMENT HEADER ?  */}
            <Presence initial={false}>
              <Show
                when={mode() !== "STATUS" /* && notifications.length === 0 */}
              >
                <Motion.div
                  // initial={{transform: "translateY(-100%)"}}
                  // animate={{transform: "translateY(0)"}}
                  // exit={{transform: "translateY(-100%)"}}
                  class={`sticky p-2 ${
                    mode() == "REVIEW" ? "bg-roma-blue" : "bg-gray-800"
                  }  text-white w-full z-10 md:px-8 text-xs md:text-sm transition-all duration-200 [transition:background_2s] boundary-outer overflow-hidden`}
                  style={{
                    top: isHeaderVisible() && !isTop() ? headerHeight : "0px",
                  }}
                >
                  {/* LOADING/ACTION PENDING BAR */}
                  <Presence>
                    <Show
                      when={aCartActionIsPending()}
                      // when={true}
                    >
                      <Motion.div
                        class="bg-roma-dark-grey text-white w-full z-10 flex  items-center justify-center p-2 absolute inset-x-0 top-0 h-full"
                        initial={{ y: "-100%", opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: "-100%", opacity: 0 }}
                      >
                        <BaseLoader
                          width={4}
                          class="mr-2"
                          colour="roma-dark-grey"
                        />
                        <p class="text-sm">Updating cart...</p>
                      </Motion.div>
                    </Show>
                  </Presence>
                  {/* END LOADING/ACTION PENDING BAR */}
                  <div class="max-w-6xl mx-auto grid grid-cols-[1fr_auto_1fr] items-center">
                    {/* SLOT 1 */}
                    <button
                      class="flex items-center text-left"
                      onClick={() => {
                        if (mode() === "OVERVIEW") {
                          navigate("/order");
                        } else {
                          navigate(`/checkout/${cart()?.ID}`);
                        }
                      }}
                    >
                      <Icon
                        path={chevronLeft}
                        class="w-4 mr-0.5 md:mr-2 mt-0.5"
                        stroke-width={2}
                      />
                      <Presence initial={false}>
                        <Show when={mode() === "OVERVIEW"}>
                          <Motion.div
                            initial={{ opacity: 0, x: "-100%" }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{
                              opacity: 0,
                              x: "-100%",
                              transition: { duration: 0.3 },
                            }}
                            transition={{ duration: 0.7 }}
                          >
                            Continue Shopping
                          </Motion.div>
                        </Show>
                        <Show when={mode() === "REVIEW"}>
                          <Motion.div
                            initial={{ opacity: 0, x: "100%" }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{
                              opacity: 0,
                              x: "100%",
                              transition: { duration: 0.3 },
                            }}
                            transition={{ duration: 0.7 }}
                          >
                            Review & Edit
                          </Motion.div>
                        </Show>
                      </Presence>
                    </button>
                    {/* SLOT 2 */}
                    <div class="child:max-sm:hidden flex justify-center items-center gap-2 text-white/80">
                      <p
                        class="border-b border-transparent  "
                        classList={{
                          "border-white text-white ": mode() === "OVERVIEW",
                        }}
                      >
                        Overview
                      </p>
                      <Show
                        when={mode() === "OVERVIEW"}
                        fallback={<div class=" w-4 border-b border-white/80" />}
                      >
                        <Icon
                          path={arrowLongRight}
                          class="w-6 text-white animate-pulse"
                        />
                      </Show>
                      <p
                        class="border-b border-transparent "
                        classList={{
                          "border-white text-white ": mode() === "REVIEW",
                        }}
                      >
                        Review & Shipping
                      </p>
                      <Show
                        when={mode() === "REVIEW"}
                        fallback={<div class=" w-4 border-b border-white/80" />}
                      >
                        <Icon
                          path={arrowLongRight}
                          class="w-6 text-white animate-pulse"
                        />
                      </Show>
                      <p class="">Place Order</p>
                    </div>
                    {/* SLOT 3 */}
                    <button
                      class="flex items-center text-right justify-end disabled:cursor-not-allowed"
                      onClick={() => {
                        navigate(`/checkout/${cart()?.ID}/review`);
                      }}
                      disabled={disableCheckoutButtons()}
                    >
                      <Presence initial={false}>
                        <Show when={mode() === "OVERVIEW"}>
                          <Motion.div
                            initial={{ opacity: 0, x: "-100%" }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{
                              opacity: 0,
                              x: "-100%",
                              transition: { duration: 0.3 },
                            }}
                            transition={{ duration: 0.7 }}
                            class="flex items-center justify-end"
                          >
                            <span>Shipping</span>
                            <Icon
                              path={chevronRight}
                              class="w-4 ml-0.5 md:ml-2 mt-0.5"
                              stroke-width={2}
                            />
                          </Motion.div>
                        </Show>
                      </Presence>
                    </button>
                  </div>
                </Motion.div>
              </Show>
            </Presence>
            {/* END REPLACEMENT HEADER */}
            <Suspense
              fallback={
                <div class="w-full flex justify-center items-center py-10">
                  <BaseLoader width={10} height={10} />
                </div>
              }
            >
              <main
                class="transition-all duration-1000 bg-white relative"
                classList={{
                  "!bg-roma-grey border-t border-gray-200": mode() == "REVIEW",
                  "border-t border-transparent": mode() !== "REVIEW",
                }}
              >
                {/*  */}
                {/* <button
                    class="border-2 border-red-500 text-red-500"
                    onClick={() =>{
                      reval();
                    }}
                  >
                    REVALIDATE
                  </button> */}
                <Show when={mode() == "STATUS"}>
                  <div class="w-full bg-faint-blue border-t print:hidden">
                    <div class="max-w-6xl mx-auto">
                      <div class="py-12">
                        <Show
                          when={searchParams.placed == "true"}
                          fallback={
                            <div class="px-8 max-w-3xl mx-auto flex max-sm:flex-col justify-between items-stretch">
                              <div>
                                <h4 class="text-4xl pb-4 font-bold">
                                  Your Order
                                </h4>
                                <p>
                                  Submitted on:{" "}
                                  <span class="text-roma-medium-grey">
                                    {cart()?.SubmittedAt
                                      ? displayDate(
                                          Date.parse(
                                            cart()?.SubmittedAt as string
                                          )
                                        )
                                      : "N/A"}
                                  </span>
                                </p>
                              </div>
                              <div class="flex flex-col  max-sm:items-start sm:items-end text-roma-blue pt-4 text-sm">
                                <A href="/shop">Back to shop</A>
                                <button
                                  onClick={() => {
                                    window.print();
                                  }}
                                >
                                  Print confirmation
                                </button>
                              </div>
                            </div>
                          }
                        >
                          <div class="px-4 flex flex-col items-center gap-4 text-center">
                            <Icon
                              path={checkCircle}
                              class="w-12 h-12 text-success-green"
                            />
                            <h3 class="flex flex-col items-center text-3xl font-bold">
                              Thanks for your order!
                              <ConfettiExplosion
                                particleSize={10}
                                duration={4000}
                                particleCount={150}
                              />
                            </h3>
                            <p class="text-lg font-light">
                              Your order has been placed and a confirmation
                              email will be sent shortly
                            </p>
                            <div class="flex justify-center items-center text-roma-blue gap-5 pt-4">
                              <A href="#">Back to shop</A>
                              <button
                                onClick={() => {
                                  window.print();
                                }}
                              >
                                Print confirmation
                              </button>
                            </div>
                          </div>
                        </Show>
                      </div>
                    </div>
                  </div>
                </Show>
                <div class="max-w-6xl mx-auto py-6 px-8 transition-all">
                  <Show when={mode() !== "STATUS"}>
                    <div class="flex flex-col gap-2 pb-2">
                      {/* <Switch>
                      <Match when={mode() == "REVIEW"}>
                        <A
                          href={`/checkout/${cart()?.ID}`}
                          class="text-roma-blue text-sm"
                        >
                          <div class="flex items-end">
                            <Icon path={chevronLeft} class="w-4" />
                            <span class="block leading-none">Cart Edit</span>
                          </div>
                        </A>
                      </Match>
                      <Match when={mode() == "OVERVIEW"}>
                        <Button
                          style="outline"
                          onClick={() => {
                            navigate("/order");
                          }}
                        >
                          <div class="flex justify-center items-center px-2">
                            <Icon path={chevronLeft} class="w-4" />
                            <span class="ml-1 pr-3">Continue Shopping</span>
                          </div>
                        </Button>
                      </Match>
                    </Switch> */}
                      <Show when={isBPS()}>
                        <BPSCheckout />
                        <Show when={BPSContainsNonCorner()}>
                          <div class="w-full border rounded-md bg-orange-50 p-4 text-center text-sm">
                            <p>
                              BPS Orders placed on behalf of customers may{" "}
                              <span class="italic">only</span> contain corner
                              samples. Please remove other products from the
                              cart to proceed.
                            </p>
                          </div>
                        </Show>
                      </Show>
                      <div class="grid sm:grid-cols-12 sm:gap-x-8 lg:gap-x-12 mt-5 items-end">
                        <div class="sm:col-span-8 row-start-1">
                          <h2 class="text-3xl font-bold">Your Cart</h2>
                          <Show
                            when={
                              carts()?.Results && carts()?.Results.length! > 1
                            }
                          >
                            <div class="h-[40px] flex items-center">
                              {cart()?.Name}{" "}
                            </div>
                          </Show>
                          {/* <Show when={import.meta.env.DEV}>
                            <p class="text-orange-500">Cart resource ID: {cart()?.ID}</p>
                            <p class="text-orange-500">Session cart ID: {session()?.cart_id}</p>
                          </Show> */}
                        </div>
                        <Show
                          when={
                            carts()?.Results &&
                            carts()?.Results.length! > 1 &&
                            mode() == "OVERVIEW"
                          }
                        >
                          <div class="flex flex-col sm:justify-end items-start sm:items-end sm:col-span-4 sm:row-start-1">
                            <button
                              class="text-sm  text-roma-blue"
                              onClick={() =>
                                setShowCartDropdown(!showCartDropdown())
                              }
                            >
                              {showCartDropdown()
                                ? "Hide Carts"
                                : "Switch Carts"}
                            </button>
                            <Presence exitBeforeEnter>
                              <Show when={showCartDropdown()}>
                                <CartDropdown
                                  carts={
                                    carts as AccessorWithLatest<ListCartResponse>
                                  }
                                />
                              </Show>
                            </Presence>
                          </div>
                        </Show>
                        {/* STOCK LEVELS CHANGED FOR ITEM */}
                        {/* TODO - test => if the item already has backorder consent, does this show? */}
                        <Show when={cart()?.ProductRequiresBackorder}>
                          <div class="border col-span-full my-2 bg-red-50 p-4 text-sm">
                            <p>
                              Stock levels have changed for some items in your
                              cart, please review/edit the affected items
                              higlighted below in order to proceed.
                            </p>
                          </div>
                        </Show>
                        {/* STOCK VERIFICATION FAILED ?  */}
                        <Show
                          when={
                            !cart()?.StockVerificationComplete &&
                            cart()?.LineCount! > 0 &&
                            cart()?.Status === "active"
                          }
                        >
                          <div class="col-span-full my-2 bg-orange-50 p-4 text-sm">
                            <p>
                              Stock levels could not be verified at this time -
                              mouldings with low stock may be placed on
                              backorder if you proceed with checkout.
                              <br /> For more details please contact{" "}
                              <A href="/support" class="text-roma-blue">
                                support
                              </A>{" "}
                              or try again later.
                            </p>
                          </div>
                        </Show>
                      </div>
                    </div>
                  </Show>
                  {/* HERE */}
                  <div class="grid md:grid-cols-12 max-md:gap-16 md:gap-12 sm:pb-16">
                    {/* LEFT PANEL */}
                    <Switch fallback={props.children}>
                      <Match when={cartError()}>
                        {(e) => {
                          return (
                            <GenericError
                              class="md:col-span-8 bg-red-100 rounded-md p-6"
                              title="There was an error retrieving your cart details."
                              error={e() as APIError}
                              actions={[
                                {
                                  label: "Switch active cart",
                                  action: () => setShowCartDropdown(true),
                                },
                                {
                                  label: "Sign out of account",
                                  action: async () => {
                                    await clearSession();
                                    navigate("/");
                                  },
                                },
                              ]}
                            />
                          );
                        }}
                      </Match>
                      {/* TODO - Handle other edgecases / errors here? */}
                      <Match
                        when={
                          cart()?.Status === "submitted" && mode() !== "STATUS"
                        }
                      >
                        <div class="md:col-span-8 flex flex-col gap-8 sm:gap-16">
                          <div
                            class={`w-full p-3 sm:p-8 ${
                              mode() === "OVERVIEW"
                                ? "bg-roma-grey"
                                : "bg-white"
                            } flex flex-col`}
                          >
                            <p class="md:text-xl ">
                              This cart has already been checked out.
                            </p>
                            <ul class="list-disc list-inside mt-4 max-md:text-sm">
                              <p class="mb-1">Suggestions:</p>
                              <li>
                                View your{" "}
                                <A
                                  href={`/checkout/${props.params.cart_id}/status`}
                                  class="text-roma-blue"
                                >
                                  Order Status
                                </A>
                              </li>
                              <li>Switch carts / Create a new cart</li>
                              <li>
                                Contact{" "}
                                <A href="/support" class="text-roma-blue">
                                  Customer Support
                                </A>
                              </li>
                            </ul>
                            <Show when={cart()?.ID}>
                              <p class=" mt-4 self-end font-light text-xs text-roma-medium-grey">
                                ID: <span class="select-all">{cart()?.ID}</span>
                              </p>
                            </Show>
                          </div>
                        </div>
                      </Match>
                    </Switch>
                    {/* RIGHT PANEL */}
                    <Show when={mode() !== "STATUS"}>
                      <div
                        class={`${
                          cart()?.Status === "active"
                            ? "max-md:row-start-1"
                            : ""
                        } md:col-span-4 flex flex-col gap-4 md:sticky top-4 max-h-screen relative max-md:border-b max-md:pb-10`}
                      >
                        <div
                          class=" bg-roma-grey flex flex-col gap-3 px-6 py-8 transition-colors duration-1000"
                          classList={{ "!bg-white": mode() == "REVIEW" }}
                        >
                          <h3 class="font-bold pb-1">Order Summary</h3>
                          <div class="flex justify-between">
                            <p class="text-sm">Subtotal</p>
                            <p class="text-sm">
                              $
                              {(
                                cart()?.Subtotal! +
                                Math.abs(cart()?.Discount || 0)
                              ).toFixed(2)}
                            </p>
                          </div>
                          <Show when={Math.abs(cart()?.Discount || 0) > 0}>
                            <div class="flex justify-between">
                              <p class="text-sm">You saved</p>
                              <p class="text-sm">
                                (${Math.abs(cart()?.Discount || 0).toFixed(2)})
                              </p>
                            </div>
                          </Show>
                          {/* START Tariff Logic */}
                          <Show
                            when={cart()?.TariffCharge}
                            // when={true}
                          >
                            <details>
                              <summary class="flex justify-between">
                                <div class="text-sm flex items-center ">
                                  <span class="inline-block">Tariff Fee</span>
                                  <Icon
                                    path={questionMarkCircle}
                                    class="ml-1 w-4 h-4 inline"
                                  />
                                </div>
                                <p class="text-sm">
                                  ${(cart()?.TariffCharge || 0).toFixed(2)}
                                </p>
                              </summary>
                              <p class="text-xs mt-2">
                                Due to current tariff regulations, items shipped
                                from Canada to the US will include a tariff fee.
                              </p>
                            </details>
                          </Show>
                          {/* END Tariff Logic */}

                          <div class="flex justify-between">
                            <p class="text-sm">Tax</p>
                            <p class="text-sm">
                              ${(cart()?.Tax || 0).toFixed(2)}
                            </p>
                          </div>
                          <div class="flex flex-col gap-4 border-t pt-4">
                            <div class="flex justify-between text-lg font-bold">
                              <p>Total</p>
                              <p>
                                ${numberWithCommas(cart()?.Amount?.toFixed(2))}
                              </p>
                            </div>
                            <Switch>
                              <Match when={mode() == "OVERVIEW"}>
                                <Button
                                  class="w-full"
                                  href={`/checkout/${cart()?.ID}/review`}
                                  disabled={disableCheckoutButtons()}
                                  onClick={() => {
                                    track(EventType.Event, {
                                      category: "begin_checkout",
                                      currency: status()?.Currency,
                                      // @ts-expect-error
                                      value: cart()?.Amount,
                                      items: cart()?.Lines.map(
                                        (
                                          item: FormattedCart["Lines"][number]
                                        ) => {
                                          const category = item.SubItems[0]
                                            ?.Moulding
                                            ? (cart() as FormattedCart)
                                                ?.Products?.[
                                                item.SubItems[0]?.Moulding
                                              ]?.Category
                                            : undefined;
                                          const collection = item.SubItems[0]
                                            ?.Moulding
                                            ? (cart() as FormattedCart)
                                                ?.Products?.[
                                                item.SubItems[0]?.Moulding
                                              ]?.Collection
                                            : undefined;
                                          return {
                                            item_id: item.SubItems[0].SKU,
                                            item_name: `${category}, ${collection}`,
                                            price: item.Amount,
                                            discount: item.Discount,
                                            quantity: item.CalculatedQuantity,
                                            item_category: item.Type,
                                            item_category2: category,
                                            item_category3: collection,
                                          };
                                        }
                                      ),
                                    });
                                    // one loop to structure the 'content_ids' and 'contents' for pixel:
                                    const { content_ids, contents } =
                                      cart()?.Lines?.reduce(
                                        (memo, item) => {
                                          memo.content_ids.push(
                                            `${item.SubItems[0].SKU}`
                                          );
                                          memo.contents.push({
                                            id: `${item.SubItems[0].SKU}`,
                                            quantity: item.Quantity,
                                          });
                                          return memo;
                                        },
                                        {
                                          content_ids: [] as string[],
                                          contents: [] as {
                                            id: string;
                                            quantity: number;
                                          }[],
                                        }
                                      ) ?? { content_ids: [], contents: [] };
                                    trackFBQ("InitiateCheckout", {
                                      currency: session()?.currency as string,
                                      value: cart()?.Amount,
                                      num_items: cart()?.LineCount,
                                      content_ids: content_ids,
                                      contents: contents,
                                    });
                                  }}
                                >
                                  Continue Checkout
                                </Button>
                              </Match>
                              <Match when={mode() == "REVIEW"}>
                                <Button
                                  class="w-full"
                                  disabled={disableCheckoutButtons()}
                                  onClick={handlePlaceOrder}
                                >
                                  Place Order
                                </Button>
                              </Match>
                            </Switch>
                            <Show when={mode() == "OVERVIEW"}>
                              <p class="text-xs text-roma-dark-grey">
                                Final freight will be calculated at time of
                                shipping
                              </p>
                            </Show>
                            <Show when={sendCartError() == true}>
                              <div class="bg-red-100 rounded-md p-3 py-4 text-sm text-roma-dark-grey font-light text-center">
                                <p>
                                  Something went wrong, please try again or
                                  contact support at{" "}
                                  <a href="tel:18002632322" rel="nofollow">
                                    1&nbsp;800&nbsp;263&nbsp;2322
                                  </a>{" "}
                                </p>
                              </div>
                            </Show>
                          </div>
                        </div>
                        <div>
                          <Show when={mode() === "REVIEW"}>
                            <div class="px-6 py-8 bg-white rounded-sm">
                              <h3 class="flex items-center font-bold pb-1 relative">
                                <span>P.O. Number</span>
                                <Icon
                                  path={questionMarkCircle}
                                  class="inline-block w-5 ml-4 peer"
                                />
                                <div class="opacity-0 peer-hover:opacity-100  transition duration-200 bg-roma-grey rounded-md text-sm font-normal shadow  p-4 absolute right-0 top-10 origin-top-right peer-hover:z-10">
                                  Add your own PO Number to be applied to this
                                  order. Letters, numbers, and hyphens only - 30
                                  character max.
                                </div>
                              </h3>
                              <div class="pt-2">
                                <PONumber />
                              </div>
                            </div>
                          </Show>
                          {/* PROMO CODE */}
                          {/* <Presence exitBeforeEnter>
                              <Show when={mode() == "REVIEW"}>
                                <div class="px-6 py-8 bg-white rounded-sm">
                                  <button class="font-bold text-lg pb-4 flex items-center">
                                  <Icon path={plus} class="w-4 inline mb-1 mr-3"/>
                                  <span>Add Promo Code</span>
                                                </button>
                                  <Accordion
                                    checked={false}
                                    name="promoCode"
                                    label="Add Promo Code"
                                    icon="Plus"
                                    iconRotation="45"
                                    iconPosition="Left"
                                  >
                                    <p class="text-xs italic text-orange-500">
                                      State: enter promo
                                    </p>
                                    <div class="m-px ">
                                      <InputWithButton />
                                    </div>
                                    <button class="mt-2 text-sm text-roma-blue">
                                      Apply
                                    </button>
                                    <p class="text-xs italic text-orange-500">
                                      State: promo applied
                                    </p>
                                    <div class="flex justify-between items-center">
                                      <div class="flex gap-2">
                                        <Icon path={tag} class="w-5 text-black" />
                                        <span class="inline-block uppercase -mb-1 font-medium">
                                          PROMO
                                        </span>
                                        <button>
                                          <Icon
                                            path={xMark}
                                            class="w-5 text-roma-blue"
                                          />
                                        </button>
                                      </div>
                                      <div>
                                        <p class="uppercase font-medium">
                                          CDN ($15.55)
                                        </p>
                                      </div>
                                    </div>
                                  </Accordion>
                                </div>
                              </Show>
                            </Presence> */}
                        </div>
                        <div class="p-8 flex flex-col  bg-white child:py-4 ">
                          <div class="relative">
                            <h3 class="flex items-center relative font-bold pb-1">
                              <span>Freight Concession</span>
                            </h3>
                          </div>
                          <div class="flex flex-col gap-2 border-b">
                            <div class="flex justify-between">
                              <h4 class="text-sm font-medium">Moulding</h4>
                              <Icon
                                path={
                                  cart()?.Freight == true
                                    ? checkCircle
                                    : minusCircle
                                }
                                class={`w-5 ${
                                  cart()?.Freight == true
                                    ? "text-success-green"
                                    : "text-roma-medium-grey"
                                }`}
                              />
                            </div>
                            <p class="text-sm font-light text-roma-dark-grey">
                              {cart()?.Freight == true
                                ? "Freight concession applied!"
                                : "Purchase does not qualify"}
                            </p>
                          </div>
                          <Show when={mode() == "REVIEW"}>
                            <p class="text-xs text-roma-dark-grey font-light !pb-0">
                              Final freight will be calculated at time of
                              shipping
                            </p>
                          </Show>
                          {/* <div class="flex flex-col gap-2">
                                <div class="flex justify-between">
                                  <h4 class="text-sm font-medium">Photo Frames</h4>
                                </div>
                                <div class="w-full h-1 bg-gray-200 rounded-full relative">
                                  <div class="absolute left-0 h-1 bg-roma-blue w-32 rounded-full" />
                                </div>
                                <p class="text-sm font-light text-roma-dark-grey">
                                  Just <span class="font-medium">$51.24</span> away from
                                  qualifying
                                </p>
                              </div> */}
                        </div>
                        <div class="text-sm text-center px-8 font-medium flex flex-col gap-4">
                          {/* <Show when={mode() == "OVERVIEW"}>
                              <p>Have a coupon? Apply it at payment in the checkout</p>
                            </Show> */}
                          <p>
                            Need help with this order?{" "}
                            <button
                              class="text-roma-blue"
                              onClick={() => {
                                // TODO
                                // window.LiveChatWidget.call("maximize");
                              }}
                            >
                              Chat now
                            </button>
                          </p>
                        </div>
                      </div>
                    </Show>
                  </div>
                </div>
                {Prompt}
              </main>
            </Suspense>
            {/* </Body> */}
          </ErrorBoundary>{" "}
        </AuthBarrier>
      </CheckoutContext.Provider>
    </>
  );
}

const EMPTYCART: FormattedCart = {
  ID: "",
  Name: "",
  ProcessDate: null,
  Subtotal: 0,
  Amount: 0,
  ShipTo: "",
  Discount: 0,
  Tax: 0,
  Freight: false,
  LineCount: 0,
  Status: "active" as const,
  CreatedAt: "",
  UpdatedAt: "",
  Lines: [],
  Products: {},
  Orders: [],
  LowStockSkus: {},
  StockVerificationComplete: false,
  ProductRequiresBackorder: false,
  DefaultPlant: "" as PlantCodeKeys,
  SubmittedAt: "",
};

export interface FormattedCart extends CartDetail {
  Orders: { Type: string }[];
  LowStockSkus: { [k: string]: InventoryByPlant };
  StockVerificationComplete?: boolean;
  ProductRequiresBackorder?: boolean;
  Lines: (CartDetail["Lines"][number] & {
    RequiresBackorderSelection?: boolean;
  })[];
}

export type CheckoutStore = {
  shipToChoice: string | undefined;
  shipCustom: boolean;
  pickup: boolean;
  PONumber: string;
  promoCode: string | undefined;
  orderNotes: string | undefined;
  orderProcess: "default" | "delay";
  shipDate?: string;
  customLocation: Partial<OrderAddress>;
  CustomerID?: string;
};

export type FormattedCartLines = {
  products: FormattedCart["Lines"];
  samples: FormattedCart["Lines"];
  photoFrame: {
    isInCart: boolean;
    cumTotal: number;
  };
  galleryFrame: {
    isInCart: boolean;
    cumTotal: number;
  };
};

export type MinAmountMet = {
  valid: boolean;
  minAmount: number;
  difference: number;
  name: string;
};
