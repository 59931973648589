import {
  Router,
  useBeforeLeave,
  BeforeLeaveEventArgs,
  useIsRouting,
} from "@solidjs/router";
import { MetaProvider, Title, Meta, Link } from "@solidjs/meta";
import { FileRoutes } from "@solidjs/start/router";
import { Suspense, createEffect, For, ErrorBoundary, onMount } from "solid-js";
import { SiteContextProviderAndSessionManager } from "./components/SiteContextProviderAndSessionManager";
import "./app.css";
import { ToastRegion } from "./components/utility/toast";
import { Fonts } from "./components/utility/scripts";
import { createAsync } from "@solidjs/router";
import { getSession } from "./services/session";

import { GenericError } from "./components/utility/errors";

// const SentryRouter = withSentryRouterRouting(Router);

export const VERSION = "2.1.10";

export default function App() {
  return (
    <Router
      // rootPreload={()=>getSession()}
      root={(props) => (
        <>
          <MetaProvider>
            <Title>Roma Moulding</Title>
            <Meta charset="utf-8" />
            <Meta
              name="viewport"
              content="width=device-width,initial-scale=1"
            />
            <Meta
              name="description"
              content="Roma Moulding is a world leader in high-quality Italian-made framing."
            />

            <Link rel="dns-prefetch" href="https://cdn.romamoulding.com" />
            <Link rel="preload" as="image" href={"/img/logo-tm.svg"} />

            {/* FONTS */}
            <Fonts />

            {/* ! GOOGLE */}
            {/* <GoogleAnalytics /> */}
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-NXHWE6C6VG"
            />
            <script>
              {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-NXHWE6C6VG');`}
              {`gtag("config", "GA_MEASUREMENT_ID", { custom_map: { dimension1: "version" } }); gtag("event", "initial_load", { version: "${VERSION}" });`}
            </script>

            <Meta
              name="google-site-verification"
              content="ryYTCNkx1lih8rehf-vtX_E5H8GfFSO0y1x2xwGC1NI"
            />

            {/* FACEBOOK PIXEL */}
            <script>
              {`!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '947694210406254');
          fbq('track', 'PageView');`}
            </script>
            <noscript>
              <img
                height="1"
                width="1"
                style="display:none"
                src="https://www.facebook.com/tr?id=947694210406254&ev=PageView&noscript=1"
              />
            </noscript>

            {/* CLOUDFLARE TURNSTILE */}
            <script
              src="https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit"
              async
              defer
            />

            <ErrorBoundary
              fallback={(err, reset) => {
                return <GenericError error={err} reset={reset} autoReport />;
              }}
            >
              <Suspense>
                <SiteContextProviderAndSessionManager>
                  <Suspense>{props.children}</Suspense>
                </SiteContextProviderAndSessionManager>
              </Suspense>
            </ErrorBoundary>
          </MetaProvider>
        </>
      )}
    >
      <FileRoutes />
    </Router>
  );
}
